/*=========================================================================================
  File Name: sidebarItems.js
  Description: Sidebar Items list. Add / Remove menu items from here.
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

import Vue from 'vue'
import Member  from '@/database/models/member';

const menuAdmin = [
  {
    url: "/admin",
    name: "Accueil",
    slug: "accueil",
    icon: "HomeIcon",
  },
  {
    url: "/admin/booking",
    name: "Planning",
    slug: "planning",
    icon: "CalendarIcon",
    submenu: [
      {
        url: '/admin/booking',
        name: 'Agenda',
        slug: 'dashboard-ecommerce',
        i18n: 'Catalogue'
      },
      
      {
        url: '/admin/groups',
        name: 'Cours Co.',
        slug: 'dashboard-ecommerce',
        i18n: 'Catalogue'
      },
    ]
  },
  {
    url: "/admin/member",
    name: "Membres",
    slug: "membres",
    icon: "UsersIcon",
  },
  /*{
    url: "/admin/document",
    name: "Documents",
    slug: "documents",
    icon: "FileIcon",
  },*/
  {
    url: "/admin/terminal/all",
    name: "Ventes",
    slug: "ventes",
    icon: "ShoppingCartIcon",
    submenu: [
      {
        url: '/admin/option/catalogue',
        name: 'Catalogue',
        slug: 'dashboard-ecommerce',
        i18n: 'Catalogue'
      },
      /*{
        url: '/admin/terminal/all',
        name: 'Facturation old',
        slug: 'dashboard-analytics',
        i18n: 'Facturation'
      },*/
      {
        url: '/admin/facturesView/all',
        name: 'Facturation',
        slug: 'dashboard-analytics',
        i18n: 'FacturationVersion'
      },
      {
        url: '/admin/encaissement',
        name: 'Encaissements',
        slug: 'dashboard-ecommerce',
        i18n: 'Encaissements'
      },
      {
        url: '/admin/creance',
        name: 'Créances',
        slug: 'dashboard-ecommerce',
        i18n: 'Créances'
      },
      // {
      //   url: '/admin/abonnements',
      //   name: 'Abonnements',
      //   slug: 'abonnements',
      //   i18n: 'Abonnements'
      // },
    //  {
    //       // pour le test
    //     url: '/admin/attestationcloture2',
    //     name: 'Attestations',
    //     slug: 'dashboard-ecommerce',
    //     i18n: 'Créances'
    //   },

    ]
  },
  {
    url: "/admin/communication",
    name: "Communication",
    slug: "communication",
    icon: "MailIcon",
    submenu: [
      {
        url : '/admin/com/scenario',
        name: 'Scenario',
        slug: 'dashboard-ecommerce',
        i18n: 'Scenario'
      },
      {
        url : '/admin/com/member',
        name: 'Campagne',
        slug: 'dashboard-ecommerce',
        i18n: 'Campagne'
      },
      // {
      //   url : '/admin/com/histo',
      //   name: 'Historique',
      //   slug: 'dashboard-ecommerce',
      //   i18n: 'Historique'
      // }
    ]
  },
  {
    url: "/admin/marketing",
    name: "Marketing",
    slug: "marketing",
    icon: "BarChartIcon",
    submenu: [
      {
        url : '/admin/marketing/relances',
        name: 'Relances',
        slug: 'relances',
        i18n: 'Relances'
      },
      {
        url : '/admin/marketing',
        name: 'Statistiques',
        slug: 'marketing',
        i18n: 'Statistiques'
      },
      {
        url : '/admin/marketing/fitnessboost',
        name: 'Fitness Boost',
        slug: 'fitnessboost',
        i18n: 'Fitness Boost'
      },
    ]
  },
]

const menuComptable = [
  {
    url: "/admin",
    name: "Accueil",
    slug: "accueil",
    icon: "HomeIcon",
  },
  {
    url: "/admin/terminal/all",
    name: "Ventes",
    slug: "ventes",
    icon: "ShoppingCartIcon",
    submenu: [
      {
        url: '/admin/option/catalogue',
        name: 'Catalogue',
        slug: 'dashboard-ecommerce',
        i18n: 'Catalogue'
      },
      {
        url: '/admin/facturesView/all',
        name: 'Facturation',
        slug: 'dashboard-analytics',
        i18n: 'FacturationVersion'
      },
      {
        url: '/admin/encaissement',
        name: 'Encaissements',
        slug: 'dashboard-ecommerce',
        i18n: 'Encaissements'
      },
      {
        url: '/admin/creance',
        name: 'Créances',
        slug: 'dashboard-ecommerce',
        i18n: 'Créances'
      }
    ]
  }
]

const user = Vue.prototype.$srvApi.getLoginUserUid()

const member = Member.getMemory(user, (mbr) => {
  return mbr
});

let menu = [] 
if(member && member.type === 'c') {
  menu = menuComptable
} else {
  menu = menuAdmin
}

export default menu
