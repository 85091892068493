<template>
  <div class="the-navbar__user-meta flex items-center" v-if="displayName">

    <div class="text-right leading-tight hidden sm:block">
      <p class="font-semibold">{{ displayName }}</p>
      <small>{{ rolep }}</small>
    </div>

    <vs-dropdown vs-custom-content vs-trigger-click class="cursor-pointer" ref="dropMenu">

      <div class="con-img ml-3">
        <img v-if="photoURL" key="onlineImg" :src="photoURL" alt="user-img" width="40" height="40" class="rounded-full shadow-md cursor-pointer block" />
      </div>

      <vs-dropdown-menu class="vx-navbar-dropdown" style="fint-size:1em;min-width:200px;">

          <vs-dropdown-item class="flex py-2 px-4 cursor-pointer hover:bg-primary hover:text-white" v-if="role !== 'c'" @click="maFiche">
            <feather-icon icon="UserIcon" svgClasses="w-4 h-4" />
            <span class="ml-2">Ma fiche</span>
          </vs-dropdown-item>

          <!-- <vs-dropdown-item class="flex py-2 px-4 cursor-pointer hover:bg-primary hover:text-white" @click="metsTache">
            <feather-icon icon="CheckSquareIcon" svgClasses="w-4 h-4" />
            <span class="ml-2">Mes taches</span>
          </vs-dropdown-item> -->

           <vs-dropdown-item class="flex py-2 px-4 cursor-pointer hover:bg-primary hover:text-white" v-if="role !== 'c'" @click="mesDocuments">
            <feather-icon icon="FileIcon" svgClasses="w-4 h-4" />
            <span class="ml-2">Documents</span>
          </vs-dropdown-item>
          

          <vs-divider class="m-1" v-if="role !== 'c'" />

          <vs-dropdown-item class="flex py-2 px-4 cursor-pointer hover:bg-primary hover:text-white" @click="monStudio">
            <feather-icon icon="HomeIcon" svgClasses="w-4 h-4" />
            <template v-if="role=='a'" >
              <span class="ml-2"><b>Mon studio</b></span>
            </template>
            <template v-else >
              <span class="ml-2"><b>Mon Compte</b></span>
            </template>
          </vs-dropdown-item>

          <vs-divider class="m-1" />

          <vs-dropdown-item
            class="flex py-2 px-4 cursor-pointer hover:bg-primary hover:text-white" @click="logout">
            <feather-icon icon="LogOutIcon" svgClasses="w-4 h-4" />
            <span class="ml-2">Deconnexion</span>
          </vs-dropdown-item>

      </vs-dropdown-menu>
    </vs-dropdown>
    {{ loadUser() }}
  </div>
</template>

<script>
import Utils from '@/assets/utils/utils';
import Member from '@/database/models/member'


export default {
  data() {
    return {
      displayName : 'Chargement ...',
      photoURL    : require("@/assets/images/portrait/small/avatar.png"),
      role        : '?',
      rolep       : 'Inconnu',
    }
  },

  /*computed: {
    activeUserInfo()
    {
      return data_tpl;
      //return this.$store.state.AppActiveUser
    }
  },*/

  //-----------------------------------------------------------------------
  // methode de routage
  //-----------------------------------------------------------------------
  methods: {
    logout() {
        this.$router.push('/logout').catch(() => {});
    },
    monStudio(){
        this.$refs.dropMenu.toggleMenu(false);
        this.$router.push('/admin/option').catch(() => {});
    },
    maFiche(){
      this.$router.push('/admin/member/'+ this.$srvApi.getLoginUserUid() ).catch(() => {});
    },
    mesDocuments(){
        this.$refs.dropMenu.toggleMenu(false);
        this.$router.push('/admin/document').catch(() => {});
    },
    metsTache(){
      //Todo
    },
    refresh(){
      this.$bddRefresh.actualise();
    },
    loadUser()
    {
      let root = this;

      //Chargement info user
      let uid = root.$srvApi.getLoginUserUid();
      Member.getMemory(uid, function(mbr)
      {
        root.displayName = Utils.StrCapital(mbr.first_name+" "+mbr.last_name);
        root.photoURL    = root.$srvApi.buildUrl('public/coach/'+mbr.uid+'/photo');
        root.role        = mbr.type;
        root.rolep       = Member.getAuthName(mbr);
      });
    }
  }


}
</script>
